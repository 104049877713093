.movies {
  margin: 0 auto;
  padding: 0;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-content: center;
  min-height: 70%;
}

.movies__button {
  margin: 50px auto 80px;
  min-width: 320px;
  min-height: 36px;
  padding: 11px 0 9px;
  color: black;
  font-size: 12px;
  line-height: 121%;
  font-weight: 500;
  border: none;
  background-color: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;  
}

.movies__message {
  margin: 25px auto 50px;
  padding: 0;
  font-size: 22px;
  line-height: 121%;
  font-weight: 400;
  color: black;
  text-align: center;
}


@media all and (max-width: 765px) {
  .movies__button {
    margin: 61px auto 89px;
    min-width: 240px;
  }
}

@media all and (max-width: 320px) {
  
}

@media all and (max-width: 1279px) {
  .movies {
    width: 89%;
  }
}
@media all and (max-width: 768px) {
  .movies {
    width: 92%;
  }
}

@media all and (max-width: 500px) {
  .movies {
    width: 91%;
  }
}

@media all and (max-width: 320px) {
  .movies {
    width: 94%;
  }
  .movies__button {
    margin: 50px auto 82px;
  }
}
