.filter-checkbox {
  margin: 32px 0 48px;
  padding: 0;
  max-width: 167px;
  display: flex;
  gap: 14px;
}

.filter-checkbox__input {
  position: absolute;
  z-index: -100;
  opacity: 0;
  visibility: hidden;
}

.filter-checkbox__input + .filter-checkbox__text {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  gap: 14px;
  position: relative;
}

.filter-checkbox__input + .filter-checkbox__text::before {
  content: "";
  display: inline-block;
  width: 36px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../images/checkbox-inactive.svg");
  background-size: 100%;
  cursor: pointer;
}

.filter-checkbox__input:checked + .filter-checkbox__text::before {
  background-image: url("../../images/checkbox-active.svg");
}

.filter-checkbox__text {
  margin: 0 auto;
  padding: 0;
  font-size: 13px;
  line-height: 138%;
  font-weight: 400;
}

@media all and (max-width: 768px) {
  .filter-checkbox {
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 61px;
  }
  .filter-checkbox__input + .filter-checkbox__text {
    gap: 16px;
    flex-direction: row-reverse;
  }
}

@media all and (max-width: 450px) {
  .filter-checkbox {
    margin: 41px auto 51px;
  }
  .filter-checkbox__text {
    font-size: 11px;
  }
  .filter-checkbox__input + .filter-checkbox__text {
    gap: 13px;
  }
}
