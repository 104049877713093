.techs {
  margin: 0 auto;
  padding: 102px 0 98px;
  background-color: #f5f5f5;
}

.techs__container {
  margin: 0 auto;
  padding: 0;
  max-width: 1140px;
}

.techs__title {
  margin: 0 auto;
  margin-top: 90px;
  padding: 0;
  font-size: 50px;
  line-height: 116%;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
}

.techs__paragraph {
  margin: 0 auto;
  margin-top: 26px;
  padding: 0;
  max-width: 460px;
  font-size: 14px;
  line-height: 143%;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
}

.techs__list {
  margin: 100px auto 0;
  padding: 0;
  max-width: 690px;
  display: grid;
  gap: 10px;
  list-style: none;
  grid-template-columns: repeat(auto-fit, minmax(84px, 1fr));
  grid-auto-rows: 1fr;
  align-items: center;
}

.techs__list-item {
  margin: 0 auto;
  padding: 21px 0 22px;
  background-color: #e8e8e8;
  min-width: 90px;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 121%;
  border-radius: 10px;  
  cursor: pointer;
}
.techs__list-link {
  text-decoration: none;
  color: black;
}

@media all and (max-width: 1279px) {
  .techs__container {
    width: 89%;
  }
}

@media all and (max-width: 768px) {
  .techs {
    padding: 92px 0;
  }
  .techs__container {
    width: 87%;
  }
  .techs__title {
    margin-top: 80px;
  }
  .techs__paragraph {
    margin-top: 22px;
    font-size: 12px;
    line-height: 150%;
  }
  .techs__list {
    margin: 83px auto 0;
    max-width: 648px;
  }
  .techs__list-item {
    padding: 21px 0;
    min-width: 84px;
    font-size: 12px;
    max-height: 57px;
  }
}

@media all and (max-width: 400px) {
  .techs {
    padding: 72px 0;
    background-color: white;
  }
  .techs__container {
    width: 89%;
  }
  .techs__title {
    margin-top: 60px;
    font-size: 30px;
    line-height: 121%;
  }
  .techs__paragraph {
    margin-top: 24px;
    font-size: 11px;
    line-height: 145%;
  }
  .techs__list {
    margin: 50px auto 0;
    max-width: 178px;
  }
  .techs__list-item {
    letter-spacing: -0.04em;
  }
}
