.movies-card {
  margin: 0;
  padding: 0;
  min-width: 270px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: auto 1fr min-content;
  justify-content: left;
  cursor: pointer;
}
.movies-card__link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  grid-column: span 2;
}

.movies-card__image {
  margin: 0;
  padding: 0;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.movies-card__title {
  margin: 14px 0 13px;
  padding: 0;
  max-width: 226px;
  min-height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 121%;
}

.movies-card__like-button {
  margin: 10px 0 0;
  padding: 0;
  background: url(../../images/like.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.3s linear;
}

.movies-card__like-button:hover {
  opacity: 0.5;
}

.movies-card__like-button_active {
  background: url(../../images/like-active.svg);
}

.movies-card__delete-button {
  margin: 10px 0 0 20px;
  opacity: 0;
  background: url(../../images/delete-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.3s linear;
}

.movies-card:hover .movies-card__delete-button {
  display: inline-block;
  opacity: 1;
}

.movies-card__duration {
  margin: 0;
  padding: 6px 0 0;
  border-top: 1px solid #e8e8e8;
  grid-column: span 2;
  font-size: 11px;
  line-height: 121%;
  color: #a0a0a0;
}

@media all and (max-width: 954px) {
  .movies-card__title {
    max-width: 295px;
    margin-bottom: 28px;
  }
}
@media all and (max-width: 768px) {
  .movies-card__delete-button {
    opacity: 1;
  }
}

@media all and (max-width: 765px) {
  
  .movies-card__title {
    max-width: 240px;
    font-size: 12px;
    line-height: 125%;
  }
  .movies-card__duration {
    font-size: 10px;
  }
}
