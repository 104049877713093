.footer {
  margin: 0 auto;
  padding: 79px 0 0;
  max-width: 1140px;
}

.footer__heading {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 20px;
  font-size: 13px;
  line-height: 121%;
  font-weight: 400;
  color: #a0a0a0;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

.footer__container {
  margin: 20px auto;
  padding: 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.footer__date {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 121%;
  font-weight: 400;
}

.footer__links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer__link {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 121%;
  font-weight: 400;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media all and (max-width: 1279px) {
  .footer {
    width: 89%;
  }
}

@media all and (max-width: 768px) {
  .footer {
    width: 92%;
  }
}

@media all and (max-width: 400px) {
  .footer {
    width: 94%;
  }
  .footer__heading {
    padding-bottom: 21px;
    font-size: 12px;
    line-height: 121%;
  }

  .footer__container {
    margin: 30px auto 0;
    flex-direction: column-reverse;
    align-items: center;
    justify-items: baseline;
  }
  .footer__date {
    margin: 30px auto 20px;
    color: #a0a0a0;
    font-size: 12px;
    line-height: 121%;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .footer__link {
    font-size: 12px;
    line-height: 121%;
  }
}
