.portfolio {
  margin: 101px auto 106px;
  padding: 0;
  max-width: 1140px;
}

.portfolio__heading {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 111%;
  font-weight: 400;
  color: #a0a0a0;
}

.portfolio__links {
  margin: 50px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.portfolio__link {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  background-image: url(../../images/arrow.svg);
  background-repeat: no-repeat;
  background-position: right 6px top 19px;
  background-size: 18px;

}
.portfolio__link:last-child {
  border-bottom: none;
}

.portfolio__link-text {
  margin: 0;
  margin-bottom: 19px;
  padding: 0;
  font-size: 30px;
  line-height: 167%;
  letter-spacing: -0.04em;
  font-weight: 400;
  color: black;  
  text-decoration: none;
}

.portfolio__link-image {
    margin: 0;
    padding: 0;
    width: 29px;
}

@media all and (max-width: 1279px) {
  .portfolio {
    width: 89%;
  }
}
@media all and (max-width: 768px) {
  .portfolio {
    width: 87%;
    margin: 91px auto 71px;
  }
  .portfolio__link-text {
    font-size: 28px;
    line-height: 178%;
  }
}

@media all and (max-width: 400px) {
  .portfolio {
    width: 91%;
    margin: 70px auto 51px;
  }
  .portfolio__heading {
    font-size: 14px;
    line-height: 143%;
  }
  .potfolio__links {
    margin-top: 40px;
  }
  .portfolio__link-text {
    font-size: 18px;
    line-height: 155%;
  }
}
