.sidebar {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  display: flex;
  justify-content: right;
  transition: transform 0.4s ease-in;
  z-index: 700;
}
.sidebar_opened {
  visibility: visible;
}

.sidebar__container {
  margin: 0;
  padding: 0;
  padding-bottom: 90px;
  min-width: 520px;
  height: 100%;
  background-color: white;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar__nav {
  margin: 159px auto auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}
.sidebar__nav-item {
  margin: 0 auto 28px;
  padding: 0;
}
.sidebar__link {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 121%;
  font-weight: 500;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.sidebar__link_active {
  border-bottom: 2px solid black;
  margin-bottom: 22px;
  padding-bottom: 4px;
}
.sidebar__link_no-underline {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.sidebar__close {
  margin: 0 auto;
  padding: 0;
  background: none;
  border: none;
  width: 31px;
  height: 31px;
  background-image: url(../../images/close-button.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
}

.sidebar__account-button {
  margin: auto auto 90px;
  display: initial;
  text-decoration: none;
}

@media all and (max-width: 590px) {
  .sidebar__container {
    min-width: 350px;
  }
}

@media all and (max-width: 350px) {
  .sidebar__container {
    margin: 0 auto;
    padding-bottom: 46px;
    min-width: 320px;
  }

  .sidebar__close {
    top: 13px;
    right: 13px;
  }
  .sidebar__nav {
    margin-top: 144px;
    min-width: 300px;
  }
  .sidebar__link {
    text-align: center;
    margin-bottom: 19px;
    padding-bottom: 7px;
  }
  .sidebar__link_active {
    margin-bottom: 15px;
  }

  .sidebar__account-button {
    margin-bottom: 46px;
  }
}
