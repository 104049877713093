.movies-cardlist {
  margin: 0 auto;
  padding: 70px 0 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 39px;
  column-gap: 20px;
  list-style: none;
  justify-self: left;
  justify-content: left;
}

.movies-cardlist__not-found-error {
  margin: 25px auto 50px;
  padding: 0;
  font-size: 22px;
  line-height: 121%;
  font-weight: 400;
  color: black;
  text-align: center;
}

@media all and (max-width: 954px) {
  .movies-cardlist {
    column-gap: 30px;
    row-gap: 31px;
  }
}

@media all and (max-width: 450px) {
  .movies-cardlist {
    padding-top: 40px;
  }
  .movies-cardlist {
    column-gap: 36px;
    row-gap: 36px;
  }
}
