.profile {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  min-height: 80%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.profile__title {
  margin: 74px auto 123px;
  padding: 0;
  font-size: 24px;
  line-height: 121%;
  font-weight: 500;
}

.profile__form {
  margin: 0 auto;
  padding: 0;
  min-width: 410px;
}

.profile__label {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 11px;
  line-height: 121%;
  font-weight: 500;
  text-align: left;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
}

.profile__label:last-of-type {
  border-bottom: none;
  margin-top: 17px;
  padding: 0;
}

.profile__input {
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
  font-size: 11px;
  text-align: right;
  line-height: 121%;
  font-weight: 400;
  border: none;
  background-color: white;
}

.profile__input_error {
  color: #ff3055;
}

.profile__error-text {
  margin: 6px 0 0;
  padding: 0;
  color: #ff3055;
  font-size: 10px;
  line-height: 121%;
}

.profile__input::placeholder {
  -webkit-placeholder-opacity: 1;
  -moz-placeholder-opacity: 1;
  opacity: 1;
  color: black;
  font-weight: 400;
}

.profile__input:focus {
  outline: none;
}

.profile__label:focus-within {
  border-bottom: 1px solid #3456f3;
}
.profile__label_error:focus-within {
  border-bottom: 1px solid #ff3055;
}
.profile__links {
  margin: 204px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  cursor: pointer;
  list-style: none;
  align-items: center;
}

.profile__link {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 121%;
  text-align: center;
  color: black;
  border: none;
  background: none;
}

.profile__link_red {
  color: #ff3055;
}

.profile__submit {
  margin: 207px auto 0;
  padding: 13px 0 15px;
  border: none;
  border-radius: 3px;
  background-color: #3456f3;
  color: white;
  font-size: 14px;
  line-height: 121%;
  font-weight: 500;
  min-width: 410px;
  cursor: pointer;
}

.profile__submit_disabled {
  background-color: #f8f8f8;
  color: #c2c2c2;
  cursor: not-allowed;
}

@media all and (max-width: 768px) {
  .profile__title {
    margin-top: 236px;
    margin-bottom: 97px;
  }
  .profile__links {
    margin-top: 194px;    
    margin-bottom: 269px;
  }
  .profile__submit {
    margin-top: 197px;    
    margin-bottom: 269px;
  }
}

@media all and (max-width: 490px) {
  .profile__form {
    min-width: 260px;
  }
  .profile__title {
    margin: 70px auto 80px;
    font-size: 24px;
  }
  .profile__links {
    margin-top: 363px;
    margin-bottom: 40px;
  }
  .profile__submit {
    margin-top: 366px;    
    margin-bottom: 40px;
  }
  .profile__link {
    font-size: 12px;
    line-height: 125%;
  }
  .profile__submit {    
    min-width: 260px;
  }
}
