.about-project {
  margin: 0 auto;
  padding: 0;
  padding-top: 110px;
  background-color: white;
  justify-items: center;
  max-width: 1140px;
}

.about-project__table {
  margin: 0 auto;
  display: grid;
  column-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr max-content;
}

.about-project__table-title {
  margin: 0;
  margin-top: 70px;
  padding: 0;
  font-size: 20px;
  line-height: 121%;
  font-weight: 400;
  letter-spacing: -0.04em;
}

.about-project__table-paragraph {
  margin: 0;
  margin-top: 26px;
  padding: 0;
  font-size: 14px;
  line-height: 143%;
  font-weight: 400;
}

.about-project__process {
  margin: 110px auto;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 36px min-content;
  align-items: center;
}

.about-project__process-scale {
  margin: 0;
  padding: 9px 14px;
  font-size: 14px;
  line-height: 121%;
  text-align: center;
  font-weight: 400;
  background-color: #f2f2f2;
  align-self: stretch;
}
.about-project__process-scale_green {
  background-color: #2be080;
}

.about-project__process-text {
  margin: 0;
  margin-top: 14px;
  padding: 0;
  font-size: 14px;
  line-height: 121%;
  text-align: center;
  font-weight: 500;
  color: #a0a0a0;
}

@media all and (max-width: 1279px) {
  .about-project {
    width: 89%;
  }
}

@media all and (max-width: 768px) {
  .about-project {
    padding-top: 90px;
    width: 87%;
  }
  .about-project__table {
    column-gap: 30px;
  }
  .about-project__table-paragraph {
    margin-top: 22px;
    font-size: 12px;
    line-height: 150%;
  }
  .about-project__process {
    margin: 93px auto 90px;
    grid-template-columns: 21% 79%;
  }
}

@media all and (max-width: 400px) {
  .about-project {
    padding-top: 70px;
    width: 89%;
  }
  .about-project__table {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
  }

  .about-project__table-title {
    margin-top: 56px;
    font-size: 18px;
    line-height: 122%;
  }
  .about-project__table-paragraph {
    margin-top: 16px;
    font-size: 11px;
    line-height: 145%;
  }
  .about-project__process {
    margin: 60px auto 70px;
    grid-template-columns: 1fr 1.84fr;
  }
  .about-project__process-scale {
    padding-top: 11px;
    font-size: 11px;
    line-height: 118%;
  }
  .about-project__process-text {
    margin-top: 10px;
    font-size: 11px;
    line-height: 118%;
  }
}
