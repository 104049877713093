.about-me {
  margin: 0 auto;
  max-width: 1140px;
  padding: 112px 0 0;
}
.about-me__info {
  margin: 66px 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-me__info-text {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.about-me__info-title {
  margin: 0;
  padding: 0;
  font-size: 50px;
  line-height: 116%;
  font-weight: 400;
  letter-spacing: -0.04em;
}

.about-me__info-subtitle {
  margin: 0;
  margin-top: 18px;
  padding: 0;
  font-size: 18px;
  line-height: 111%;
  font-weight: 500;
}

.about-me__info-paragraph {
  margin: 26px 0 99px;
  padding: 0;
  max-width: 600px;
  font-size: 14px;
  line-height: 157%;
  font-weight: 400;
}

.about-me__info-link {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 121%;
  font-weight: 500;
  text-decoration: none;
  color: black;
}

.about-me__photo {
  margin: 0;
  padding: 0;
  width: 270px;
  height: 327px;
  border-radius: 10px;
  object-fit: cover;
  grid-row: span 4;
  grid-column: 2;
}

@media all and (max-width: 1279px) {
  .about-me {
    width: 89%;
  }
}

@media all and (max-width: 1000px) {
  .about-me__info-paragraph {
    max-width: 430px;
    margin-bottom: 90px;
    font-size: 13px;
  }
}

@media all and (max-width: 800px) {
  .about-me {
    padding-top: 90px;
    width: 87%;
  }
  .about-me__info-title {
    font-size: 40px;
    line-height: 100%;
  }
  .about-me__info-subtitle {
    margin-top: 16px;
    font-size: 12px;
    line-height: 150%;
  }
  .about-me__photo {
    width: 255px;
    height: 307px;
  }
  .about-me__info-paragraph {
    margin-bottom: 87px;
    margin-top: 20px;
    max-width: 364px;
    font-size: 12px;
    line-height: 150%;
  }
}

@media all and (max-width: 720px) {
  .about-me__info-paragraph {
    max-width: 320px;
  }
}
@media all and (max-width: 595px) {
  .about-me {
    padding-top: 70px;
  }
  .about-me__info {
    margin-top: 60px;
    flex-direction: column-reverse;
  }

  .about-me__info-title {
    margin-top: 40px;
    font-size: 30px;
    line-height: 120%;
  }
  .about-me__info-subtitle {
    margin-top: 20px;
    font-size: 11px;
    line-height: 145%;
  }

  .about-me__info-paragraph {
    margin-bottom: 40px;
    max-width: 292px;
    font-size: 11px;
    line-height: 145%;
  }
  .about-me__photo {
    width: 292px;
    height: 352px;
  }
}
@media all and (max-width: 320px) {
  .about-me {
    padding-top: 70px;
    width: 91%;
  }
  .about-me__photo {
    margin: 0 auto;
  }
}
