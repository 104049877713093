.promo {
  margin: 0 auto;
  padding: 0;
  background-color: #073042;
}

.promo__container {
  margin: 0 auto;
  padding: 0;
  max-width: 1140px;
  display: grid;
  grid-template-columns: repeat(2 min-content);
  grid-template-rows: repeat(3 max-content);
  column-gap: 190px;
  justify-content: center;
}

.promo__heading {
  margin: 0;
  margin-top: 75px;
  padding: 0;
  max-width: 640px;
  font-size: 50px;
  color: #ffffff;
  line-height: 116%;
  font-weight: 400;
}

.promo__paragraph {
  margin: 0;
  margin-top: 36px;
  padding: 0;
  max-width: 310px;
  font-size: 13px;
  color: #ffffff;
  line-height: 120%;
  font-weight: 400;
  opacity: 50%;
}

.promo__logo {
  margin: 0;
  margin-top: 75px;
  padding: 0;
  width: 310px;
  height: 304px;
  grid-row: 1 / span 3;
  grid-column: 2;
  justify-self: right;
}

.promo__button {
  margin: 0;
  margin-top: 146px;
  margin-bottom: 75px;
  padding: 0;
  background: none;
  max-width: 129px;
  min-height: 36px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  text-align: center;
  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
}

@media all and (max-width: 1279px) {
  .promo__container {
    width: 89%;
  }
}
@media all and (max-width: 896px) {
  .promo__container {
    column-gap: 70px;
  }
}

@media all and (max-width: 768px) {
  .promo__container {
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .promo__logo {
    margin-top: 140px;
  }
  .promo__heading {
    margin-top: 48px;
    text-align: center;
    font-size: 40px;
    line-height: 125%;
  }
  .promo__paragraph {
    text-align: center;
    margin-top: 22px;
    max-width: 260px;
  }
  .promo__button {
    margin-top: 92px;
    min-width: 129px;
    margin-bottom: 76px;
  }
}

@media all and (max-width: 400px) {
  .promo__container {
    width: 91%;
  }
  .promo__logo {
    margin-top: 70px;
    width: 210px;
    height: 206px;
  }
  .promo__heading {
    margin-top: 44px;
    font-size: 30px;
    line-height: 127%;
    letter-spacing: -0.04em;
  }
  .promo__paragraph {
    margin-top: 24px;
  }
  .promo__button {
    margin-top: 80px;
    margin-bottom: 30px;
  }
}
