.tooltip {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  visibility: hidden;
  opacity: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: visibility 0.3s, opacity 0.3s linear;
  z-index: 700;
}

.tooltip_opened {
  visibility: visible;
  opacity: 1;
}

.tooltip__close {
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  background: url(../../images/tooltip-close.svg);
  background-size: 100%;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: -40px;
}

.tooltip__container {
  margin: 0 auto;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-width: 430px;
  color: black;
  position: relative;
}

.tooltip__info {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.tooltip__info-image {
  margin: 60px auto 32px;
  width: 120px;
  height: 120px;
}
.tooltip__info-title {
  margin: 0 auto 60px;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 121%;
  text-align: center;
  max-width: 358px;
}

@media screen and (max-width: 499px) {
  .tooltip__container {
    min-width: 282px;
  }
  .tooltip__close {
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
  .tooltip__info-title {
    font-size: 20px;
    line-height: 120%;
    max-width: 244px;
  }
}
