.saved-movies {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 230px;
  max-width: 1140px;
  min-height: 50%;
}
.saved-movies__not-found-error {
  margin: 25px auto 50px;
  padding: 0;
  font-size: 22px;
  line-height: 121%;
  font-weight: 400;
  color: black;
  text-align: center;
}

@media all and (max-width: 1279px) {
  .saved-movies {
    width: 92%;
    padding-bottom: 140px;
  }
}

@media all and (max-width: 500px) {
  .saved-movies {
    width: 93%;
  }
}
