.register {
  margin: auto auto;
  padding: 0;
  max-width: 396px;
  height: 100%;
}

@media all and (max-width: 472px) {
  .register {
    max-width: 260px;
  }
}
