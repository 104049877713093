.main {
  margin: 0 auto;
  padding: 0;
}
.main__heading {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 23px;
  font-size: 22px;
  line-height: 121%;
  font-weight: 400;
  letter-spacing: -0.04em;
  border-bottom: 1px solid black;
}

@media all and (max-width: 400px) {
  .main__heading {
    padding-bottom: 28px;
    font-size: 18px;
  }
}
