.app {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: "Inter", "Arial", sans-serif;
  width: 100vw;
  height: 100vh;
  color: #000000;
  font-size: 0;
  line-height: 0;
}

.app__link {
  transition: opacity 0.3s linear;
}

.app__link:hover {
  opacity: 0.7;
}

.app__button {
  transition: opacity 0.3s linear;
}

.app__button:hover {
  opacity: 0.8;
}
